import { useEffect, ReactNode } from "react";

interface IModal {
  children: ReactNode;
  onClose: () => void;
  wrapperStyle?: React.CSSProperties;
  className?: string;
}

export default function Modal({
  children,
  className = "",
  onClose,
  wrapperStyle,
  ...rest
}: IModal) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={`modal-outer ${className}`} {...rest}>
      <div className="modal">
        <div
          role="button"
          onClick={onClose}
          style={{
            position: "absolute",
            top: -40,
            right: -40,
            cursor: "pointer",
            padding: 20,
          }}
        >
          <div
            style={{
              width: 30,
              height: 3,
              backgroundColor: "var(--d)",
              transform: "rotate(45deg)",
              boxShadow: "0 0 5px #00000080",
            }}
          />
          <div
            style={{
              width: 30,
              height: 3,
              backgroundColor: "var(--d)",
              transform: "rotate(135deg)",
              boxShadow: "0 0 5px #00000080",
              marginTop: -3,
            }}
          />
        </div>
        <div className="modal-inner">{children}</div>
      </div>
    </div>
  );
}
