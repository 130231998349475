import styled from "styled-components";

export const Container = styled.footer`
  background-color: #2b2b2b;
  padding: 3rem 12rem;
  margin-top: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    font-size: 2.8rem;
    display: flex;
  }

  p {
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .social-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    a {
      display: flex;
    }
    img {
      border-radius: 4px;
      filter: grayscale(100);
      transition: filter 0.2s;
      &:hover {
        filter: grayscale(0);
      }
    }

    img,
    span {
      font-size: 3rem;
      width: 4rem;
    }
  }

  @keyframes spinning {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 800px) {
    padding: 4rem 10rem;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
  @media (max-width: 600px) {
    padding: 4rem 1rem;
    p {
      font-size: 1.2rem;
    }
  }
`;
