import { Container } from "./styles";
import mailboxIcon from "../../assets/mailbox-up.svg";

export function Contact() {
  return (
    <Container id="contact">
      <header>
        <h2>Contact me</h2>
      </header>
      <div className="contacts">
        <div>
          <img src={mailboxIcon} alt="Email" />
          <a href="mailto:paola.consorti@gmail.com">paola.consorti@gmail.com</a>
        </div>
      </div>
    </Container>
  );
}
