import { Container } from "./styles";

import linkedinIcon from "../../assets/linkedin.png";
import museumIcon from "../../assets/museum.jpg";
import behanceIcon from "../../assets/behance.svg";
import prozIcon from "../../assets/proz.jpg";
import PaolaConsortiLogo from "../../assets/pc-bold.svg";

export function Footer() {
  return (
    <Container className="footer">
      <div style={{ flex: 1 }}>
        <div className="logo">
          <a href="/" style={{ display: "flex" }}>
            <img
              src={PaolaConsortiLogo}
              alt="Paola Consorti"
              style={{ width: "2em", height: "2em", filter: "saturate(110%)" }}
            />
          </a>
        </div>
      </div>
      <div>
        <p>
          Made with
          <span style={{ color: "var(--red)" }}>❤</span>
        </p>
      </div>

      <div
        className="social-media"
        style={{ flex: 1, justifyContent: "flex-end" }}
      >
        <a
          href="https://www.linkedin.com/in/paola-consorti-0b38a915b/"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
        <a
          href="https://www.behance.net/paolaconsorti"
          title="Behance"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={behanceIcon}
            alt="Behance"
            style={{ backgroundColor: "#1769ff" }}
          />
        </a>
        <a
          href="https://museumapp.co/baoola"
          title="Museum (Sktchy)"
          target="_blank"
          rel="noreferrer"
        >
          <img src={museumIcon} alt="Museum (Sktchy)" />
        </a>
        <a
          href="https://www.proz.com/translator/2521660"
          title="Proz"
          target="_blank"
          rel="noreferrer"
        >
          <img src={prozIcon} alt="Proz" />
        </a>
      </div>
    </Container>
  );
}
