import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import ProgressBar from "../ProgressBar/ProgressBar";

export default function MyLanguages() {
  return (
    <Container id="languages">
      <h2>Languages</h2>

      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="ITALIAN (mother tongue)"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "100%",
              color: "var(--a)",
            },
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="ENGLISH"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "90%",
              color: "var(--b)",
            },
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="BRAZILIAN-PORTUGUESE"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "65%",
              color: "var(--c)",
            },
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="CHINESE"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "40%",
              color: "var(--d)",
            },
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="SPANISH"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "20%",
              color: "var(--e)",
            },
          ]}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ProgressBar
          label="FRENCH"
          backgroundColor="var(--bg-theme)"
          visualParts={[
            {
              percentage: "10%",
              color: "var(--f)",
            },
          ]}
        />
      </ScrollAnimation>
    </Container>
  );
}
