import styled from "styled-components";

export const Container = styled.section`
  margin-top: 20rem;

  header {
    text-align: center;
    h2 {
      text-align: center;
      font-size: 2.4em;
    }
    p {
      color: var(--green);
      font-weight: 500;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    place-items: center;
    margin-top: 8rem;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      gap: 2rem;
      background-color: var(--green);
      border-radius: 1.4rem;
      padding: 1.6rem 5rem;
      cursor: pointer;
      transition: background-color 0.25s;
      img {
        width: 3rem;
        filter: invert(1) drop-shadow(0 0 5px rgb(0 0 0 / 50%));
      }
      a {
        color: #fff;
        font-weight: 500;
        font-size: 1.3em;
        -webkit-transition: color 0.25s;
        transition: color 0.25s;
        text-shadow: 0 0 5px rgb(0 0 0 / 50%);
      }
      &:hover {
        background-color: var(--pink);
        a {
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .contacts {
      flex-direction: column;
      div {
        width: 100%;
        flex-direction: column;
      }
    }
  }
`;
