import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import ProgressBar from "../ProgressBar/ProgressBar";

export default function MySkills() {
  return (
    <Container id="skills">
      <h2>Skills</h2>

      <ScrollAnimation animateIn="fadeIn">
        <ScrollAnimation animateIn="fadeIn">
          <ProgressBar
            label="GRAPHIC DESIGN"
            backgroundColor="var(--bg-theme)"
            visualParts={[
              {
                percentage: "100%",
                color: "var(--two)",
              },
            ]}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <ProgressBar
            label="UI / UX"
            backgroundColor="var(--bg-theme)"
            visualParts={[
              {
                percentage: "100%",
                color: "var(--two)",
              },
            ]}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <ProgressBar
            label="TRANSLATION / LOCALIZATION"
            backgroundColor="var(--bg-theme)"
            visualParts={[
              {
                percentage: "90%",
                color: "var(--one)",
              },
            ]}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <ProgressBar
            label="DRAWING"
            backgroundColor="var(--bg-theme)"
            visualParts={[
              {
                percentage: "80%",
                color: "var(--three)",
              },
            ]}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <ProgressBar
            label="HTML / CSS / JS"
            backgroundColor="var(--bg-theme)"
            visualParts={[
              {
                percentage: "30%",
                color: "var(--four)",
              },
            ]}
          />
        </ScrollAnimation>
      </ScrollAnimation>
    </Container>
  );
}
