import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Container } from "./styles";
import Resume from "../../assets/Resume-PaolaConsorti.pdf";
import PaolaConsortiLogo from "../../assets/paola-consorti.svg";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import NavLink from "./NavLink";

export function Header() {
  const [isActive, setActive] = useState(false);
  const scrollPosition = useScrollPosition();

  function toggleTheme() {
    const html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  function closeMenu() {
    setActive(false);
  }

  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          <img
            src={PaolaConsortiLogo}
            alt="Paola Consorti"
            className={`header-logo${
              scrollPosition.scrollY > 800 ? "" : " hidden"
            }`}
          />
        </HashLink>

        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>

        <nav className={isActive ? "active" : ""}>
          <NavLink to="#home" onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink to="#projects" onClick={closeMenu}>
            Projects
          </NavLink>
          <NavLink to="#skills" onClick={closeMenu}>
            Skills
          </NavLink>
          <NavLink to="#experience" onClick={closeMenu}>
            Experience
          </NavLink>
          <NavLink to="#contact" onClick={closeMenu}>
            Contact me
          </NavLink>
          <a
            href={Resume}
            target="_blank"
            className="button resume"
            rel="noreferrer"
          >
            <b style={{ fontWeight: 700 }}>Resume</b>
          </a>
        </nav>

        <div
          id="menu-toggle"
          onClick={() => setActive(!isActive)}
          style={{
            cursor: "pointer",
            width: 20,
            height: 20,
            display: "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            aria-expanded={isActive ? "true" : "false"}
            aria-haspopup="true"
            aria-label={isActive ? "Close menu" : "Open menu"}
            className={isActive ? "menu active" : "menu"}
          />
        </div>
      </Router>
    </Container>
  );
}
