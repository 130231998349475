import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container } from "./styles";
import folderIcon from "../../assets/icons/folder.svg";
import Modal from "./Modal";
import ProjectsList from "./ProjectsList";

const importAll = (r: any) => r.keys().map(r);

const myWorks = [
  {
    title: "Icons",
    images: importAll(
      (require as any).context(
        "../../assets/works/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    ),
  },
  {
    title: "Logos",
    images: importAll(
      (require as any).context(
        "../../assets/works/logos",
        false,
        /\.(png|jpe?g|svg)$/
      )
    ),
  },
  {
    title: "Drawings",
    images: importAll(
      (require as any).context(
        "../../assets/works/drawings",
        false,
        /\.(png|jpe?g|svg|gif)$/
      )
    ),
    wrapperStyle: { padding: 0 },
    imgStyle: { objectFit: "cover" },
  },
];

export default function MyWorks() {
  const [worksToShow, setWorksToShow] = useState<null | Record<string, any>>(
    null
  );

  return (
    <Container id="projects">
      <h2>Projects</h2>

      <div className="projects">
        <div style={{ width: "100%", paddingBottom: 100 }}>
          <ProjectsList />
        </div>

        {myWorks.map((work) => (
          <ScrollAnimation key={work.title} animateIn="flipInX">
            <div className="project" onClick={() => setWorksToShow(work)}>
              <header>
                <img
                  src={folderIcon}
                  width={150}
                  alt="Folder"
                  // style={{ filter: "hue-rotate(293deg)" }}
                />
              </header>
              <div className="body">
                <h3>{work.title}</h3>
              </div>
            </div>
          </ScrollAnimation>
        ))}

        {!!worksToShow && (
          <Modal
            className={worksToShow.title}
            onClose={() => setWorksToShow(null)}
          >
            <div className="works-container">
              {worksToShow.images.map((image: any) =>
                ["Logos", "Drawings"].includes(worksToShow.title) ? (
                  <a
                    key={image}
                    href={image}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ margin: 0, display: "flex", overflow: "hidden" }}
                  >
                    <img src={image} alt="Work" style={worksToShow.imgStyle} />
                  </a>
                ) : (
                  <img src={image} alt="Work" style={worksToShow.imgStyle} />
                )
              )}
            </div>
          </Modal>
        )}
      </div>
    </Container>
  );
}
