import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15rem;

  h2 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 10rem;
  }

  .projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    .project {
      cursor: pointer;
      padding: 2rem 6rem;
      margin: 2rem;
      /* background-color: #2b2b2b; */
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #fff;
      text-align: center;
      &:hover {
        transform: translateY(-5px);
        /* background-color: var(--pink); */
      }

      header {
        /* display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 3.6rem; */
        .project-links {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        a > img {
          width: 2.6rem;
        }
      }

      h3 {
        margin-bottom: 2rem;
      }

      p {
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a {
          color: #ffff;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover {
            color: var(--green);
          }
        }
      }

      footer {
        margin-top: auto;
        .tech-list {
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }
    }

    .modal-outer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000050;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2000;

      .modal {
        max-width: 90%;
        max-height: calc(90% - 10em);
        display: flex;
        position: relative;
        > .modal-inner {
          background-color: #fefefe;
          border-radius: 2em;
          padding: 1%;
          box-shadow: 0 0 2em #00000050;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 100%;
          flex: 1;
        }
      }
    }
    .Drawings .modal-inner {
      padding: 0 !important;
    }

    .works-container {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: auto 200px 3fr;
      background-color: #00000010;
      user-select: none;
      img {
        background-color: #fff;
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .Logos,
    .Drawings {
      .works-container {
        gap: 0.5rem;
        grid-template-columns: auto 15vw 3fr;
        img {
          width: 15vw;
          height: 15vw;
          cursor: pointer;
          transition: all 0.2s ease;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    .projects {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 480px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @media (max-width: 740px) {
    .projects {
      grid-template-columns: 1fr;
      .works-container {
        grid-template-columns: auto 100px 2fr !important;
        img {
          width: 100px !important;
          height: 100px !important;
          object-fit: contain;
        }
      }
    }
  }
`;
