import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { GiLipstick } from "react-icons/gi";
import { RiStarSmileFill } from "react-icons/ri";

import { ReactComponent as PenToolIcon } from "../../assets/pen-tool.svg";
import { ReactComponent as NotebookIcon } from "../../assets/notebook-pen.svg";
import { ReactComponent as ReceptionIcon } from "../../assets/reception-desk.svg";
import { ReactComponent as SkincareIcon } from "../../assets/skincare.svg";
import { ReactComponent as MicrophoneIcon } from "../../assets/microphone.svg";
import { ReactComponent as TranslationIcon } from "../../assets/translation.svg";

import { Container } from "./styles";

const experiences = [
  {
    years: "02/2022 - present",
    title: "Graphic & UI/UX Designer",
    company: "Sounder",
    tech: "Design and creation of wireframes (Lo-fi and Hi-fi) and prototyping. Also creating mockups + design icons and social media graphics. Overall responsible for the whole UI/UX of the mobile app.",
    Icon: PenToolIcon,
  },
  {
    years: "03/2023 - 09/2023",
    title: "UI/UX Designer",
    company: "Go InfoTeam",
    tech: "I've been responsible of designing and prototyping UX/UI mockups for web and mobile apps (Adobe XD, Figma). I've been working also on creating Brand Designs and Rebranding. Additionally, my work extended to drawing articulate illustrations for European Food Safety Authority (EFSA)'s storymaps.",
    Icon: PenToolIcon,
  },
  {
    years: "05/2021 - 08/2021",
    title: "Transcriber",
    company: "Appen",
    tech: "Transcriptions of audios.",
    Icon: NotebookIcon,
  },
  {
    years: "03/2019 - 05/2019",
    title: "Translator",
    company: "Sounder",
    tech: "Translations of the elevator pitch and spot texts.",
    Icon: TranslationIcon,
  },
  {
    years: "03/2019 - 05/2019",
    title: "VoiceOver talent",
    company: "Sounder",
    tech: "VoiceOver (in English) of the official spot.",
    Icon: MicrophoneIcon,
  },
  {
    years: "05/2015 - 09/2019",
    title: "Front-Office",
    company: "Zerouno Computer",
    tech: "Occasional work, mostly during summer.",
    Icon: ReceptionIcon,
  },
  {
    years: "10/2015 - 12/2015",
    title: "Cosmetologist",
    company: "Ernesi",
    tech: "(Internship)",
    Icon: GiLipstick,
  },
  {
    years: "10/2014 - 11/2014",
    title: "Beautician",
    company: "Alessandra Olivieri Centro Estetico",
    tech: "(Internship)",
    Icon: SkincareIcon,
  },
];

export default function Experience() {
  return (
    <Container id="experience">
      <h2>Experience</h2>

      <div style={{ color: "var(--black)" }}>
        <VerticalTimeline>
          {experiences.map((el, i) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={el.years}
              iconStyle={{
                background: "var(--pink)",
                color: "#fff",
                textAlign: "center",
              }}
              icon={<el.Icon width={50} height={50} fill="#fff" />}
              key={i}
            >
              {/* <div style={{ textAlign: "left", marginBottom: "4px" }}>
                {el.mainTech}
              </div> */}

              <h3
                className="vertical-timeline-element-title"
                style={{ textAlign: "left" }}
              >
                {el.title}
              </h3>
              <h4
                className="vertical-timeline-element-subtitle"
                style={{ textAlign: "left" }}
              >
                <i style={{ color: "var(--pink)" }}>{el.company}</i>
              </h4>
              <div
                style={{ textAlign: "left", marginTop: 15, fontSize: "0.9em" }}
              >
                {el.tech}
              </div>
            </VerticalTimelineElement>
          ))}
          <VerticalTimelineElement
            iconStyle={{
              background: "var(--green)",
              color: "#fff",
              textAlign: "center",
            }}
            icon={<RiStarSmileFill width={50} height={50} fill="#fff" />}
          />
        </VerticalTimeline>
      </div>
    </Container>
  );
}
