import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "./styles";
import Icon from "../../assets/icon4.svg";

export function About() {
  return (
    <Container id="about">
      <div className="about-text" style={{ lineHeight: 1.5 }}>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>
            My passion for <i>all things artistic</i> began when I started
            drawing as a <b>self-taught</b> artist, carrying it with me all the
            time. Lately, this passion of mine has also expanded into the
            digital: this is where my learning process towards{" "}
            <b>Graphic Design</b> comes to life.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.4 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            Before I was a Graphic Designer, I studied many <b>languages</b> and
            I've translated stuff. So far, my passion for <b>translation</b> has
            never died.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.6 * 1000}
          // style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            I am aware that I want to learn many things, but I think this is my
            best quality.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={0.8 * 1000}
          style={{ marginTop: "2rem" }}
        >
          <p>
            In my <b>work experience</b>, I have learned to organize my time and
            work in team projects. I have often challenged myself on things
            above my capabilities, but by facing them with self-determination
            and willingness to learn, I have always come out on top.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={1 * 1000}
          style={{ marginTop: "2rem" }}
        >
          <p>
            <i>So:</i> here I am, ready to get in the game! 🚀
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={1 * 1000}
          style={{ marginTop: "2rem" }}
        >
          <br />
          <p>
            <b><span style={{ color: "var(--blue)" }}>Tools:</span> Adobe Illustrator, Photoshop, InDesign, XD, Figma, Canva and Procreate.</b>
          </p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.6 * 1000}>
          <img src={Icon} alt="Placeholder" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
