import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "./styles";
import FigmaIcon from "../../assets/tools/Figma.svg";
import XdIcon from "../../assets/tools/Xd.svg";
import IllustratorIcon from "../../assets/tools/Illustrator.svg";
import PhotoshopIcon from "../../assets/tools/Photoshop.svg";
import InDesignIcon from "../../assets/tools/InDesign.svg";
import SketchIcon from "../../assets/tools/Sketch.svg";

const tools = [
  {
    name: "Figma",
    logo: FigmaIcon,
  },
  {
    name: "Adobe XD",
    logo: XdIcon,
  },
  {
    name: "Adobe Illustrator",
    logo: IllustratorIcon,
  },
  {
    name: "Adobe Photoshop",
    logo: PhotoshopIcon,
  },
  {
    name: "Adobe InDesign",
    logo: InDesignIcon,
  },
  {
    name: "Sketch",
    logo: SketchIcon,
  },
];

export default function Tools() {
  return (
    <Container id="tools">
      <h2>Tools</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 30,
          flexWrap: "wrap",
        }}
      >
        {tools.map((tool, i) => (
          <ScrollAnimation key={tool.name} animateIn="bounce" delay={i * 50}>
            <img
              src={tool.logo}
              alt={tool.name}
              title={tool.name}
              style={{
                width: "calc(50px + 2vw)",
                height: "calc(50px + 2vw)",
                display: "block",
              }}
            />
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
