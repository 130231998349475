import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { NavHashLink, NavHashLinkProps } from "react-router-hash-link";

type NavLinkProps = {
  to: string;
  onClick?: (...args: any) => any;
  style?: NavHashLinkProps["style"];
  children?: ReactNode;
} & NavHashLinkProps;

export default function NavLink({
  to,
  onClick,
  style,
  children,
  ...rest
}: NavLinkProps) {
  const { hash } = useLocation();

  return (
    <NavHashLink
      smooth
      to={to}
      onClick={onClick}
      className={hash === to ? "nav-active" : ""}
      style={{ ...style }}
    >
      {children}
    </NavHashLink>
  );
}
