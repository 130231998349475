import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15rem;

  h2 {
    text-align: center;
    font-size: 2.4em;
    margin-bottom: 10rem;
  }
  /* 
  path {
    fill: currentColor;
  } */

  .vertical-timeline-element-date {
    color: #fff;
    transition: color 0.5s;
    font-size: 0.5em !important;
  }

  .vertical-timeline-element-icon svg {
    transform: scale(1.3);
  }
  .vertical-timeline::before {
    background: transparent;
  }
  .vertical-timeline-element:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 30px;
    left: 18px;
    height: calc(100%);
    width: 4px;
    background: white;
  }
  .vertical-timeline-element-content {
    border-radius: 1em;
    font-size: calc(1em + 0.3vw);
    padding: 1em;
  }

  @media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns
      .vertical-timeline-element:not(:last-child)::before {
      left: 50%;
      top: 60px;
      margin-left: -2px;
    }
  }
`;
